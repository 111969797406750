import React, { useEffect } from 'react';
import { graphql, PageProps, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Row } from 'react-bootstrap';

import { formatPhoneNumber } from 'util/string';
import { formatPrice } from 'schema/price.schema';
import { accountHasInsuranceSelector } from 'state/account/account.selectors';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import CtaBlock, { CtaBlockButtonProps } from 'components/cta-block/CtaBlock';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import PillBottleIcon from 'assets/icons/pillBottle.svg';
import PlaneIcon from 'assets/icons/plane.svg';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import './index.style.scss';

//
// --- Types ---

interface PrescriptionConfirmationProps extends PageProps<GatsbyTypes.PrescriptionConfirmationDataQuery> {}

//
// --- PrescriptionConfirmation Component ---

const PrescriptionConfirmation: React.FC<PrescriptionConfirmationProps> = (props) => {
    const { data } = props;

    const { t } = useTranslation();
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();

    const handleTransferPrescriptionClick = React.useCallback<NonNullable<CtaBlockButtonProps['onClick']>>(() => {
        resetForm();
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription');
    }, [resetForm, setPrescriptionFlowType]);

    const { blueSkyBackground } = data;

    const {
        DrugPrice: drugPrice,
        DrugName: drugName,
        Qty: quantity,
        Strength: strength,
        DrugForm: drugForm,
        PharmacyName: pharmacyName,
        PharmacyPhone: pharmacyPhone,
        RxNumber: rxNumber,
        PackageDisplay: packageDisplay
    } = transferPrescriptionState;

    const hasPackageDisplay = !!packageDisplay;
    const hasRxNumber = !!rxNumber;
    const showDrugPriceColumn = !accountHasInsurance && !!drugPrice;

    // if no data, navigate back to main prescription screen
    useEffect(() => {
        if (!drugName) {
            navigate('/secure/prescription');
        }
    }, []);

    return (
        <WorkflowLayout
            backgroundImage={blueSkyBackground}
            className="prescription-request-confirmation"
            metaData={{ nodeTitle: t('pages.prescriptionConfirmation.title') }}
            useRoundedCorners={false}
        >
            <WorkflowLayoutFormWrapper
                className="prescription-request-confirmation-layout-form-wrapper"
                eyebrowText={t('pages.prescriptionConfirmation.eyebrowText')}
                title={t('pages.prescriptionConfirmation.headlineText')}
            >
                <>
                    <Row className="mt-5">
                        <Col
                            xs={12}
                            md={12}
                            lg={{ span: 10, offset: 1 }}
                            className="prescription-request-confirmation__border"
                        >
                            <h2 className="h3 prescription-request-confirmation__heading">
                                {t('pages.prescriptionConfirmation.sectionHeaders.prescriptionInformation')}
                            </h2>
                        </Col>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col xs={12} lg={showDrugPriceColumn ? 6 : 12}>
                                    <div className="h3 prescription-request-confirmation__subheading text-uppercase">
                                        {drugName}
                                    </div>
                                    <p className="prescription-request-confirmation__content">
                                        <small>
                                            {t('prescriptionLayout.finalStep.qty', { Qty: quantity })}{' '}
                                            {hasPackageDisplay && (
                                                <>
                                                    {' '}
                                                    <span className="prescription-request-confirmation__punctuation">
                                                        &middot;
                                                    </span>{' '}
                                                    {packageDisplay}
                                                </>
                                            )}
                                            <span className="prescription-request-confirmation__punctuation">
                                                &middot;
                                            </span>{' '}
                                            {strength}{' '}
                                            <span className="prescription-request-confirmation__punctuation">
                                                &middot;
                                            </span>{' '}
                                            {drugForm}
                                            {hasRxNumber && (
                                                <>
                                                    {' '}
                                                    <span className="prescription-request-confirmation__punctuation">
                                                        &middot;
                                                    </span>{' '}
                                                    {rxNumber}
                                                </>
                                            )}
                                        </small>
                                    </p>
                                </Col>
                                {showDrugPriceColumn && (
                                    <Col xs={12} lg={6}>
                                        <div className="discount-total">
                                            <div className="h3 prescription-request-confirmation__subheading">
                                                {formatPrice(drugPrice)}
                                            </div>
                                            <p className="prescription-request-confirmation__content text-uppercase mb-3 mr-0 ">
                                                <small className="font-weight-bold">
                                                    {t('prescriptionLayout.finalStep.rxItemDiscountOnlyTotal')}
                                                </small>
                                            </p>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col
                            xs={12}
                            md={12}
                            lg={{ span: 10, offset: 1 }}
                            className="prescription-request-confirmation__border"
                        >
                            <h2 className="h3 prescription-request-confirmation__heading">
                                {t('pages.prescriptionConfirmation.sectionHeaders.pharmacyInformation')}
                            </h2>
                        </Col>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <div className="h3 prescription-request-confirmation__subheading">{pharmacyName}</div>
                            <p className="prescription-request-confirmation__content">
                                <span className="d-flex">
                                    <small>{formatPhoneNumber(pharmacyPhone)}</small>
                                </span>
                            </p>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <Disclaimer disclaimerText={t('pages.prescriptionConfirmation.orderNotification')} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col
                            xs={12}
                            md={12}
                            lg={{ span: 10, offset: 1 }}
                            className="prescription-request-confirmation__border"
                        >
                            <h2 className="h3 prescription-request-confirmation__heading">
                                {t('pages.prescriptionConfirmation.sectionHeaders.quickLinks')}
                            </h2>
                        </Col>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col xs={12} md={6} lg={4} className="p-1">
                                    <CtaBlock
                                        className="w-100"
                                        iconContent={
                                            <img
                                                alt={t(
                                                    'pages.prescriptionConfirmation.cta.transferPrescription.imageAlt'
                                                )}
                                                height={32}
                                                src={PlaneIcon}
                                            />
                                        }
                                        onClick={handleTransferPrescriptionClick}
                                        variant="button"
                                    >
                                        {t('pages.prescriptionConfirmation.cta.transferPrescription.label')}
                                    </CtaBlock>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="p-1">
                                    <CtaBlock
                                        className="w-100"
                                        iconContent={
                                            <img
                                                alt={t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                                                height={32}
                                                src={PillBottleIcon}
                                            />
                                        }
                                        to="/secure/medicine-cabinet"
                                    >
                                        {t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                                    </CtaBlock>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default PrescriptionConfirmation;

//
// --- GraphQL ---

export const query = graphql`
    query PrescriptionConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
            }
        }
    }
`;
