import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import classNames from 'classnames';

import './CtaBlock.style.scss';

//
// --- Types ---

// note: could not figure out how to type `ref` so ignoring for now. Hopefully its not needed

interface CtaBlockCommonProps {
    iconContent?: React.ReactNode;
}

export interface CtaBlockLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'>, CtaBlockCommonProps {
    variant?: 'link';
}

export interface CtaBlockButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'ref'>, CtaBlockCommonProps {
    variant: 'button';
}

export type CtaBlockProps = CtaBlockLinkProps | CtaBlockButtonProps;

// helper that should match names of properties destructured from props
type DestructuredProps = 'className' | 'children' | 'iconContent' | 'variant';

//
// --- CtaBlock Component ---

const CtaBlock: React.FC<CtaBlockProps> = (props) => {
    const { children, className, iconContent, variant = 'link', ...remainingGatsbyLinkProps } = props;

    const hasIcon = !!iconContent;

    if (variant === 'button') {
        return (
            <button
                className={classNames('cta-block cta-block--button d-inline-block', className)}
                {...(remainingGatsbyLinkProps as Omit<CtaBlockButtonProps, DestructuredProps>)}
            >
                {hasIcon && <div className="cta-block__icon">{iconContent}</div>}
                <div className="cta-block__body">{children}</div>
            </button>
        );
    }

    return (
        <GatsbyLink
            className={classNames('cta-block cta-block--link d-inline-block', className)}
            {...(remainingGatsbyLinkProps as Omit<CtaBlockLinkProps, DestructuredProps>)}
        >
            {hasIcon && <div className="cta-block__icon">{iconContent}</div>}
            <div className="cta-block__body">{children}</div>
        </GatsbyLink>
    );
};

export default CtaBlock;
